import Api from '@/services/Api'

export default {
  getBillingAccounts(payload) {
    return Api().get('renewBot/billingAccounts')
  },
  getBillingLicences(payload) {
    return Api().get('renewBot/billingLicences')
  },
}
