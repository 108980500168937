<template>
  <div>
    <input
      type="text"
      class="searchInput"
      placeholder="Search..."
      v-model="search" />

    <v-data-table
      :headers="headers"
      :items="countersFiltered"
      :items-per-page="-1"
      show-select
      v-model="selected"
      item-key="id"
      hide-default-footer>
    </v-data-table>
  </div>
</template>

<script>
import renewBotServices from '../services/renewBot.services'
export default {
  data() {
    return {
      selected: [],
      search: '',
      billingAccounts: [],
      billingLicences: [],

      headers: [
        {
          text: 'Counter',
          align: 'start',
          sortable: true,
          value: 'counter',
        },
        {
          text: 'Billing Account',
          align: 'start',
          sortable: true,
          value: 'billingAccount.name',
        },
        {
          text: 'Active',
          align: 'start',
          sortable: true,
          value: 'params.valid',
        },
        {
          text: 'Price',
          align: 'start',
          sortable: false,
        },
        {
          text: 'Next Date',
          align: 'start',
          sortable: true,
          value: 'renewDate',
        },
      ],
    }
  },
  computed: {
    countersFiltered() {
      if (!this.search) {
        return this.billingLicences
      }

      const mySearch = this.search.toLowerCase()

      return this.billingLicences.filter((item) => {
        return (
          item.counter.toLowerCase().includes(mySearch.toLowerCase()) ||
          item.billingAccount.name.toLowerCase().includes(mySearch.toLowerCase())
        )
      })
    },
  },
  created() {
    this.refresh()
  },
  methods: {
    refresh() {
      renewBotServices.getBillingAccounts().then((response) => {
        this.billingAccounts = response.data
      })
      renewBotServices.getBillingLicences().then((response) => {
        this.billingLicences = response.data
      })
    },
  },
}
</script>

<style scoped>
.searchInput {
  border-radius: 12px;
  border-width: thin;
  border-style: solid;
  padding: 11px;
  margin: 3px;
  width: 180px;
  height: 15px;
  font-size: 12px;
}
</style>
